import React, { useState } from "react";
import "./App.css";
import { Grid, Button, CardContent, Typography, Card } from "@mui/material";

interface HabitProps {
  emoji: any;
  name: string;
}

const HabitCard = (props: HabitProps) => {
  const [count, setCount] = useState(0);
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" align="center">
          {props.emoji}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setCount(count + 1);
          }}
        >
          Track {props.name}
        </Button>
        <Typography variant="h6" align="center">
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};

function App() {
  return (
    <>
      <Typography variant="h1" align="center" paddingTop={10}>
        Baby Tracker
      </Typography>
      <Grid container spacing={3} justifyContent="center" paddingTop={8}>
        <Grid item>
          <HabitCard emoji={"💩"} name={"Diaper"} />
        </Grid>
        <Grid item>
          <HabitCard emoji={"🍼"} name={"Feeding"} />
        </Grid>
        <Grid item>
          <HabitCard emoji={"😴"} name={"Sleep"} />
        </Grid>
      </Grid>
    </>
  );
}

export default App;
